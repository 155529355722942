<template>
  <div class="aboutus">

    <el-tabs :tab-position="tabPosition" style="height: 960px" v-model="activeName">
      <el-tab-pane name="aboutus">
           <span slot="label" class="leftnav"><span class="icon icon1"></span>公司介绍</span>
           <div class="main ">
                <div class="pd20">  <img src="@img/aboutus.png" alt="" class="topimg"></div>
             
                <el-divider></el-divider>
                <div class="pd20" v-html="aboutus">
                </div>
           </div>
      </el-tab-pane>
     <el-tab-pane name="ysxy">
           <span slot="label"  class="leftnav"><span class="icon icon2"></span>隐私协议</span>
            <div class="main">
  <div class="pd20"><img src="@img/ysxytop.png" alt="" class="topimg"></div>
                <el-divider></el-divider>
                <div class="pd20" v-html="ysxy"> 
                 </div>
           </div>
      </el-tab-pane>
      <el-tab-pane name="yhfwxy">
           <span slot="label"  class="leftnav"><span class="icon icon3"></span>用户服务协议</span>
            <div class="main">
  <div class="pd20"><img src="@img/yhfwxytop.png" alt="" class="topimg"></div>
                <el-divider></el-divider>
                <div class="pd20" v-html="yhfwxy">
                 </div>
           </div>
      </el-tab-pane>
      <el-tab-pane name="rzxy">
           <span slot="label"  class="leftnav"><span class="icon icon4"></span>认证协议</span>
            <div class="main">
                 <div class="pd20"><img src="@img/rzxytop.png" alt="" class="topimg"></div>
                <el-divider></el-divider>
                <div class="pd20" v-html="rzxy">
                </div>
           </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      activeName:"",
      tabPosition: "left",
      aboutus:"",
      ysxy:"",
      yhfwxy:"",
      rzxy:""
    };
  },
    watch:{
     $route(to, from) {
      if(to.query.id){
            this.activeName=to.query.id;
      }
    }
  },
  computed: {
    ...mapState(["isLogin", "userInfo"]),
  },
  mounted() {
      if(this.$route.query.id){
            this.activeName=this.$route.query.id;
      }else{
           this.activeName="aboutus";
      }
     
        this.$http.aboutus().then(res=>{
            this.aboutus=res.data.text;
        })
         this.$http.yszc().then(res=>{
            this.ysxy=res
        })
         this.$http.yhfwxy().then(res=>{
            this.yhfwxy=res
        })
         this.$http.agreement().then(res=>{
            this.rzxy=res.data.agreement
        })
  },

  methods: {},
};
</script>
<style scoped lang="scss">
@import "@/styles/mixin.scss";
.aboutus{
    margin-bottom: 20px;
    background: #fff;
}
.el-divider--horizontal{
    margin: 0;
}
/deep/.pd20{
    padding: 20px ;
    font-size: 16px;
    line-height: 2;
    img{
        max-width: 100%;
    }
}
/deep/.el-tabs__content{
    height: 960px;
    overflow-y: auto;
}
.main{
    // padding: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
      img{
        display: block;
        width:100% ;
        height: auto;
    }
}
/deep/.el-tabs__header{
    background: #D8E9FF;
    margin-right: 0!important;
}
/deep/.el-tabs__item{
    width: 300px;
    height: 80px;
    font-size: 20px;
   .leftnav{
      padding-top: 18px;
   }
    &.is-active{
        background: #fff;
    }
}
/deep/.el-tabs--left .el-tabs__active-bar.is-left, /deep/.el-tabs--left .el-tabs__nav-wrap.is-left::after{
    left: 0;
    right: auto!important;
}

/deep/.el-tabs--left .el-tabs__active-bar.is-left, /deep/.el-tabs--left .el-tabs__active-bar.is-right, /deep/.el-tabs--right .el-tabs__active-bar.is-left, /deep/.el-tabs--right .el-tabs__active-bar.is-right{
    width: 6px;
}

.leftnav{
    display: flex;
    align-items: center;
    .icon{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 10px;
        &.icon1{
            background: url("~@img/gsjs.png") no-repeat center;
            background-size: auto 20px;
        }
        &.icon2{
            background: url("~@img/ysxy.png") no-repeat center;
            background-size: auto 20px;
        }
        &.icon3{
            background: url("~@img/yhxy.png") no-repeat center;
            background-size: auto 20px;
        }
        &.icon4{
            background: url("~@img/rzxy.png") no-repeat center;
            background-size: auto 20px;
        }
    }

}
.is-active .leftnav,/deep/.el-tabs__item:hover{
    .icon{
    
        &.icon1{
            background: url("~@img/gsjs-on.png") no-repeat center;
            background-size: auto 20px;
        }
        &.icon2{
            background: url("~@img/ysxyon.png") no-repeat center;
            background-size: auto 20px;
        }
        &.icon3{
            background: url("~@img/yhxyon.png") no-repeat center;
            background-size: auto 20px;
        }
        &.icon4{
            background: url("~@img/rzxyon.png") no-repeat center;
            background-size: auto 20px;
        }
    }
}
</style>
